
body {
  background-color: #618e3f;
  text-align: center;
  align-content: center;
  font-size: 24px;
  color: white;
  padding: 0 10%;
}

p {
  font-size: 0.8em;
}

.appHolder {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 85vh;
}

.app {
  background-color: #486b22;
  height: 100%;
  overflow-y: hidden;
  margin-top: 10px;
  border-radius: 15px;
  display: flex;
}

.mainapp {
  scrollbar-color: #334c18 #618e3f;
  scrollbar-width: thin;
  overflow-y: scroll;
  overflow-x: visible;
  height: 100%;
  border-radius: 15px;
  width: 70%;
}

.subredditList {
  width: 30%;
}

.header {
  background-color: #2f4228;
  border-radius: 15px;
  padding: 15px;
}

.post {
  padding: 15px;
  background-color: #334c18;
  border-radius: 15px;
  margin: 5px 5px 15px 5px;
  transition: all 0.25s ease;
}

.post:hover {
  cursor: pointer;
  scale: 1.005;
  z-index: 2;
}

.post h5 {
  margin-top: 0px;
  margin-bottom: 10px;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  padding: 5px 10px;
}

.post img {
  height: 295px;
  width: 295px;
  object-fit: cover;
  border-radius: 15px;
  border: 5px solid rgba(0, 0, 0, 0.35);
}

.postFlex {
  display: flex;
  height: 100%;
}

.postFlex p {
  margin-left: 15px;
  margin-top: 0px;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  min-height: 100%;
  max-height: 285px;
  text-align: left;
  overflow-y:hidden;
}

.postBar {
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
  height: 25px;
}
.postBar p {
  margin: 2px 15px;
}

.voteBar {
  justify-items: center;
  justify-self: center;
  width: 75px;
  margin-right: 10px;
}

.voteBar p {
  min-height: fit-content;
  background-color: rgba(0, 0, 0, 0);
  margin: 0;
  text-align: center;
  padding: 0;
}
.voteBar button {
  width: 25px;
  margin: 0;
}

.header h4 {
  margin: 15px;
}

.App-link {
  color: #61dafb;
}

.subredditList {
  margin: 5px;
}

.subredditList h5{
  padding: 25px 0;
  margin: 0;
  margin-bottom: 5px;
  border-radius: 15px;
  background-color: #2f4228;
}

.subredditLink {
  height: 75px;
  display: flex;
  border-radius: 45px;
  border: 5px solid rgba(0, 0, 0, 0.35);
  margin-bottom: 5px;
  background-color: #334c18;
  overflow:hidden;
  transition: all 0.25s ease;
}

.subredditLink:hover {
  cursor: pointer;
  scale: 1.01;
}

.subredditLink img {
  height: 75px;
  width: 75px;
  object-fit: cover;
  border-radius: 50%;
}

.subredditLink p {
  text-align: left;
  margin-left: 15px;
  text-shadow: 2px 2px 5px black;
  align-self: center;
}